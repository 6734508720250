import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton } from 'shared/ui';
import { MessageEvent, StatusEvent } from 'pubnub';
import { addECGPackage, resetECG } from 'pages/session-room/store/ecg';
import { ConnectionStatus } from '../connection-status';
import { useSessionRoomDispatch, useSessionRoomState } from '../../context';
import { ConnectionStatusEnum } from '../connection-status/model';
import {
  VitalsBlock,
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderIcon,
  HeaderTitle,
  Body,
  SensorBlocks,
} from './styles';
import {
  VitalTemperature,
  VitalGlucose,
  VitalSysDia,
  VitalSp02,
  VitalEKG,
} from './ui';
import { VitalMessage } from './model';
import { generateVitalObject } from './utils';
import '../../store/init';

export const VitalsWrapper: FC = () => {
  const { t } = useTranslation(['session-room']);
  const { sessionPubNub } = useSessionRoomState();
  const dispatch = useSessionRoomDispatch();
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatusEnum>(ConnectionStatusEnum.PENDING);

  const handleStatusEvent = useCallback((statusEvent: StatusEvent) => {
    if (statusEvent.category === 'PNConnectedCategory') {
      setConnectionStatus(ConnectionStatusEnum.CONNECTED);
      return;
    }

    setConnectionStatus(ConnectionStatusEnum.DISCONNECTED);
  }, []);

  const handleMessage = useCallback(
    (message: VitalMessage) => {
      if (message.typeString === 'ECG') {
        addECGPackage(message.data);
        return;
      }
      dispatch({
        type: 'SET_VITALS_DATA',
        payload: generateVitalObject(message),
      });
    },
    [dispatch]
  );

  const handleMessageEvent = useCallback(
    ({ message }: MessageEvent) => {
      handleMessage({
        data: message.data,
        typeString: message.type_string,
      });
    },
    [handleMessage]
  );

  useEffect(() => {
    if (sessionPubNub) {
      sessionPubNub.instance.addListener({
        message: handleMessageEvent,
        status: handleStatusEvent,
      });
      sessionPubNub.instance.subscribe({
        channels: sessionPubNub.channels,
      });

      return () => {
        sessionPubNub.instance.removeListener({
          message: handleMessageEvent,
          status: handleStatusEvent,
        });

        sessionPubNub.instance.unsubscribe({
          channels: sessionPubNub.channels,
        });
      };
    }

    return undefined;
  }, [handleMessage, handleMessageEvent, handleStatusEvent, sessionPubNub]);

  const handleClose = useCallback(() => {
    dispatch({
      type: 'TOGGLE_SHOW_VITALS',
      payload: {
        isShow: false,
      },
    });
  }, [dispatch]);

  useEffect(() => resetECG, []);

  return (
    <VitalsBlock>
      <Header>
        <HeaderLeft>
          <HeaderIcon />
          <HeaderTitle>{t('session-room:VITALS')}</HeaderTitle>
        </HeaderLeft>

        <HeaderRight>
          <ConnectionStatus status={connectionStatus} />
          <CloseButton onClick={handleClose} />
        </HeaderRight>
      </Header>

      <Body>
        <SensorBlocks>
          <VitalSysDia />
          <VitalTemperature />
          <VitalSp02 />
          <VitalGlucose />
        </SensorBlocks>

        <VitalEKG />
      </Body>
    </VitalsBlock>
  );
};
